<script lang="ts" setup>
import { useSelectSummaryItem, type ILeaseSummaryField } from '@register'
import { formatSummaryValue } from '@register/utils/summary'
import { IEntity } from '~/composables/useAPI/types'
import dayjs from 'dayjs'
import { leaseStatusObject } from '~/constants/LeaseStatus'
import { openNotesModal } from '@register/components/NotesModal/composables/useNotesModal'

interface Props {
  field: ILeaseSummaryField
  index: number
}
const props = defineProps<Props>()

const leaseId = getCurrentLeaseId()
const { dateFormat } = useCurrentLease()
const { toggle, isSelected, isSelectable } = useSelectSummaryItem({
  persist: true,
})

const isOpen = ref(false)

const textValue = computed(() => formatSummaryValue(props.field.value?.value))

const onAddNote = (fieldId: string, fieldName: string): void => {
  openNotesModal(IEntity.LEASE, leaseId, fieldName, fieldId)
}
</script>

<template>
  <div
    v-auto-animate
    :class="[
      isOpen &&
        'ring:ring-gray-500 bg-gray-850 ring-primary/50 z-20 rounded-lg ring-2',
    ]"
  >
    <div
      class="relative flex gap-1 px-4 before:absolute before:-left-2 before:bottom-0 before:top-0 before:z-50 before:block before:w-2"
      :class="[
        isSelectable(field) && 'hover:before:bg-primary/30 cursor-pointer',
        isSelected(field) && 'before:!bg-primary',
      ]"
    >
      <div
        class="flex-auto"
        :class="[isSelectable(field) ? 'cursor-pointer' : 'cursor-default']"
        tabindex="0"
        role="button"
        @keyup.enter="toggle(field)"
        @click="toggle(field)"
      >
        <div
          class="relative flex flex-auto flex-col justify-between bg-inherit text-sm print:bg-white print:!text-black"
        >
          <!-- Title -->
          <div
            class="sticky -top-4 z-10 -mx-4 flex flex-none justify-between text-sm font-semibold text-white print:bg-white print:!text-black"
          >
            <div
              class="flex w-full justify-between overflow-hidden px-4"
              :class="[
                index % 2 === 0 ? 'bg-[#3a3a3a]' : 'bg-[#333333]',
                isOpen ? 'rounded-t-lg' : '',
              ]"
            >
              <span class="flex items-center gap-1">
                <VTooltip
                  v-if="isSelectable(field)"
                  class="cursor-help"
                  :delay="10"
                  placement="top"
                  :distance="12"
                  :auto-hide="true"
                >
                  <span>
                    <Icon name="backlinkingOnly" />
                  </span>
                  <template #popper>
                    <div class="text-xs">Check the highlight</div>
                  </template>
                </VTooltip>

                {{ field.name }}
              </span>
              <div class="z-20 my-1 flex gap-0">
                <!-- History -->
                <VTooltip
                  v-if="field.history?.before?.length"
                  class="cursor-help"
                  :delay="10"
                  placement="top"
                  :distance="12"
                  :auto-hide="true"
                >
                  <Button
                    v-if="!!field.history?.before || !!field.history?.after"
                    color="secondary"
                    class="fill-warning text-warning bg-gray-650/30 h-6 min-w-7 rounded-md px-1"
                    outer-class="relative"
                    icon="clock-time-arow-left"
                    @click.prevent="isOpen = !isOpen"
                  />

                  <template #popper>
                    <div class="text-xs">Check the Changes history</div>
                  </template>
                </VTooltip>

                <!-- Notes -->
                <!-- v-if="field.notesCount" -->
                <VTooltip
                  class="cursor-help"
                  :delay="10"
                  placement="top"
                  :distance="12"
                  :auto-hide="true"
                >
                  <Button
                    color="transparentPrimary"
                    class="h-6 flex-row-reverse items-center gap-px rounded-md bg-gray-700/5 px-1 text-gray-200"
                    icon="document-edit"
                    @click.prevent="onAddNote(field.id, field.name)"
                  >
                    <span
                      v-if="field.notesCount"
                      class="text-[9px] text-gray-400"
                    >
                      ({{ field.notesCount }})
                    </span>
                  </Button>
                  <template #popper>
                    <div class="text-xs">
                      {{
                        field.notesCount === 1
                          ? `1 note`
                          : `${field.notesCount} notes`
                      }}
                    </div>
                  </template>
                </VTooltip>
              </div>
            </div>
          </div>

          <!-- Value -->
          <div class="my-2 flex-auto text-sm print:bg-white">
            <RegisterSummaryFieldOptionExerciseDetails
              v-if="field.value?.value && field.id === 'OptionExerciseDetails'"
              :field="field"
              :value="field.value"
            />
            <RegisterSummaryFieldRentIncreaseTable
              v-else-if="field.value?.value && field.id.includes('Table')"
              :field="field"
              :value="field.value"
            />
            <RegisterSummaryFieldPropertyAddress
              v-else-if="field.value?.value && field.id === 'PropertyAddress'"
              :field="field"
              :value="field.value"
            />
            <div v-else-if="field.id === 'Lease__Status'">
              {{
                leaseStatusObject[
                  field.value?.value as keyof typeof leaseStatusObject
                ].label
              }}
            </div>
            <div
              v-else-if="field.value?.value && field.id.includes('Date')"
              class="flex-auto text-sm print:bg-white"
            >
              <time>
                {{
                  dayjs(field.value?.value).isValid()
                    ? dayjs(field.value?.value).format(dateFormat)
                    : field.value?.value
                }}
              </time>
            </div>
            <div v-else class="flex-auto text-sm print:bg-white">
              <span
                class="relative flex flex-col whitespace-pre-line print:bg-white"
              >
                {{ textValue }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <RegisterSummaryHistory
      v-if="isOpen && field.history?.before?.length"
      :field="field"
      class="rounded-b-lg"
      @close="isOpen = false"
    />
  </div>
</template>
