export const formatSummaryValue = (
  value: string | number | boolean | null,
): string => {
  if (value === true) {
    return 'Yes'
  }

  if (value === false) {
    return 'No'
  }

  if (isNullish(value)) {
    return 'N/A'
  }

  return String(value)
}
